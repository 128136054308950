import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';

interface SimpleResponse {
  status: string;
}

interface ProcessesResponse {
  status: string;
  processes: Array<Process>;
}

interface ProcessesCountResponse {
  status: string;
  count: number;
}

export interface Process {
  id: number;
  uid: string;
  key: string;
  description: string;
  name: string;
  version: number;
  bpmn?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ProcessesService {

  constructor(public http: HttpClient) {
  }

  all() {
    return this.get(environment.apiURL[localStorage.getItem('country')] + '/processes');
  }

  single(aid) {
    return this.get(environment.apiURL[localStorage.getItem('country')] + '/processes/' + aid);
  }

  get(edge): Promise<Array<Process>> {
    return new Promise<Array<Process>>((resolve, reject) => {
      if(localStorage.getItem('access_token') !== null) {
        const authorizationHeader = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
        this.http.get<ProcessesResponse>(edge, {
          headers: authorizationHeader,
          responseType: 'json'
        }).subscribe(data => {
          if(data.status === 'success') {
            resolve(data.processes);
          }
          else {
            reject();
          }
        }, () => {
          reject();
        });
      }
      else {
        reject();
      }
    });
  }

  count(): Promise<number> {
    return new Promise<number>((resolve, reject) => {
      if(localStorage.getItem('access_token') !== null) {
        const authorizationHeader = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
        this.http.get<ProcessesCountResponse>(environment.apiURL[localStorage.getItem('country')] + '/processes/count', {
          headers: authorizationHeader,
          responseType: 'json'
        }).subscribe(data => {
          if(data.status === 'success') {
            resolve(data.count);
          }
          else {
            reject();
          }
        }, () => {
          reject();
        });
      }
      else {
        reject();
      }
    });
  }

  start(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if(localStorage.getItem('access_token') !== null) {
        const authorizationHeader = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
        this.http.get<SimpleResponse>(environment.apiURL[localStorage.getItem('country')] + '/processes/start', {
          headers: authorizationHeader,
          responseType: 'json'
        }).subscribe(data => {
          if(data.status === 'success') {
            resolve(true);
          }
          else {
            reject();
          }
        }, () => {
          reject();
        });
      }
      else {
        reject();
      }
    });
  }

  stop(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if(localStorage.getItem('access_token') !== null) {
        const authorizationHeader = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
        this.http.get<SimpleResponse>(environment.apiURL[localStorage.getItem('country')] + '/processes/stop', {
          headers: authorizationHeader,
          responseType: 'json'
        }).subscribe(data => {
          if(data.status === 'success') {
            resolve(true);
          }
          else {
            reject();
          }
        }, () => {
          reject();
        });
      }
      else {
        reject();
      }
    });
  }
}
