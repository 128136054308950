import {Component, OnInit, EventEmitter, Output} from '@angular/core';
import {Card} from '../../dashboard/cards/card';
import {TranslateService} from '@ngx-translate/core';
import {ProcessesService} from '../processes.service';
import {SessionService} from '../../commons/session.service';

@Component({
  selector: 'app-processes-card',
  templateUrl: './processes-card.component.html',
  styleUrls: ['./processes-card.component.scss'],
})
export class ProcessesCardComponent implements OnInit, Card {

  @Output() clickEvent = new EventEmitter<string>();

  public cardTitle: string;
  public cardSubtitle: string;
  public processesCount: number;

  constructor(public translate: TranslateService, public session: SessionService, public processes: ProcessesService) {
    this.cardTitle = '';
    this.cardSubtitle = '';
    this.processesCount = 0;
  }

  ngOnInit() {
    this.fetchTranslations();
    this.fetchProcessesCount();
  }

  fetchTranslations() {
    this.translate.get('processes.card_title').subscribe(cardTitle => {
      this.cardTitle = cardTitle;
    });
    this.translate.get('processes.card_subtitle').subscribe(cardSubtitle => {
      this.cardSubtitle = cardSubtitle;
    });
  }

  fetchProcessesCount() {
    this.processes.count().then(processesCount => {
      this.processesCount = processesCount;
    }).catch((() => this.session.logout()));
  }

  navigateToRoute(path: string) {
    this.clickEvent.emit(path);
  }

}
