import {Component, OnInit, Output, EventEmitter} from '@angular/core';
import {Card} from '../../dashboard/cards/card';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-ml-repository-card',
  templateUrl: './ml-repository-card.component.html',
  styleUrls: ['./ml-repository-card.component.scss'],
})
export class MLRepositoryCardComponent implements OnInit, Card {

  @Output() clickEvent = new EventEmitter<string>();
  cardTitle: string;
  public cardSubtitle: string;

  constructor(public translate: TranslateService) {
    this.cardTitle = '';
    this.cardSubtitle = '';
  }

  ngOnInit() {
    this.fetchTranslations();
  }

  fetchTranslations() {
    this.translate.get('ml_repository.card_title').subscribe(cardTitle => {
      this.cardTitle = cardTitle;
    });
    this.translate.get('ml_repository.card_subtitle').subscribe(cardSubtitle => {
      this.cardSubtitle = cardSubtitle;
    });
  }

  navigateToRoute(path: string) {
    this.clickEvent.emit(path);
  }

}
