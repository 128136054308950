import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {MonitorAgent} from './monitor-agent';

export interface MonitorsResponse {
  status: string;
  monitors: Array<MonitorAgent>;
}

export interface MonitorsCountResponse {
  status: string;
  count: number;
}

interface SimpleResponse {
  status: string;
}

@Injectable({
  providedIn: 'root'
})
export class MonitorsService {

  constructor(public http: HttpClient) {
  }

  all(): Promise<Array<MonitorAgent>> {
    return this.getMonitors('/monitors');
  }

  single(mid): Promise<Array<MonitorAgent>> {
    return this.getMonitors('/monitor/' + mid);
  }

  user(): Promise<Array<MonitorAgent>> {
    return this.getMonitors('/monitors/user');
  }

  count(): Promise<number> {
    return this.getMonitorsCount('/monitors/user/count');
  }

  update(monitors): Promise<void> {
    return new Promise<void>(((resolve, reject) => {
      if(localStorage.getItem('access_token') !== null) {
        const authorizationHeader = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
        this.http.post<SimpleResponse>(environment.apiURL[localStorage.getItem('country')] + '/monitors/user', monitors, {
          headers: authorizationHeader,
          responseType: 'json'
        }).subscribe(data => {
            if(data.status === 'success') {
              resolve();
            }
            else {
              reject();
            }
          },
          () => reject()
        );
      }
      else {
        reject();
      }
    }));
  }

  getMonitors(edge): Promise<Array<MonitorAgent>> {
    return new Promise<Array<MonitorAgent>>(((resolve, reject) => {
      if(localStorage.getItem('access_token') !== null) {
        const authorizationHeader = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
        this.http.get<MonitorsResponse>(environment.apiURL[localStorage.getItem('country')] + edge, {
          headers: authorizationHeader,
          responseType: 'json'
        }).subscribe(data => {
            if(data.status === 'success') {
              resolve(data.monitors);
            }
            else {
              reject();
            }
          },
          () => reject()
        );
      }
      else {
        reject();
      }
    }));
  }

  getMonitorsCount(edge): Promise<number> {
    return new Promise<number>(((resolve, reject) => {
      if(localStorage.getItem('access_token') !== null) {
        const authorizationHeader = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
        this.http.get<MonitorsCountResponse>(environment.apiURL[localStorage.getItem('country')] + edge, {
          headers: authorizationHeader,
          responseType: 'json'
        }).subscribe(data => {
            if(data.status === 'success') {
              resolve(data.count);
            }
            else {
              reject();
            }
          },
          () => reject()
        );
      }
      else {
        reject();
      }
    }));
  }
}
