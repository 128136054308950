import {Component, OnInit, Output, EventEmitter} from '@angular/core';
import {Card} from '../../dashboard/cards/card';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-upload-map-card',
  templateUrl: './upload-map-card.component.html',
  styleUrls: ['./upload-map-card.component.scss'],
})
export class UploadMapCardComponent implements OnInit, Card {

  @Output() clickEvent = new EventEmitter<string>();
  cardTitle: string;

  constructor(public translate: TranslateService) {
    this.cardTitle = '';
  }

  ngOnInit() {
    this.fetchTranslations();
  }

  fetchTranslations() {
    this.translate.get('upload_map.card_title').subscribe(cardTitle => {
      this.cardTitle = cardTitle;
    });
  }

  navigateToRoute(path: string) {
    this.clickEvent.emit(path);
  }

}
