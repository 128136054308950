import {AfterViewChecked, Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Card} from '../../dashboard/cards/card';
import {MonitorsService} from '../monitors.service';
import {Router} from '@angular/router';
import {SessionService} from '../../commons/session.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-monitors-card',
  templateUrl: './monitors-card.component.html',
  styleUrls: ['./monitors-card.component.scss'],
})
export class MonitorsCardComponent implements OnInit, AfterViewChecked, Card {

  @Output() clickEvent = new EventEmitter<string>();
  public cardTitle: string;
  public cardSubtitle: string;
  public count: number;

  constructor(public router: Router, public translate: TranslateService,
              public session: SessionService, public monitors: MonitorsService) {
    this.cardTitle = '';
    this.cardSubtitle = '';
    this.count = 0;
  }

  ngOnInit() {
    this.fetchTranslations();
    this.fetchMonitorsCount();
  }

  ngAfterViewChecked() {
    this.session.reload('/', 'monitors-card', this.fetchMonitorsCount.bind(this));
  }

  navigateToRoute(path: string) {
    this.clickEvent.emit(path);
  }

  fetchTranslations() {
    this.translate.get('monitors.card_title').subscribe(cardTitle => {
      this.cardTitle = cardTitle;
    });
    this.translate.get('monitors.card_subtitle').subscribe(cardSubtitle => {
      this.cardSubtitle = cardSubtitle;
    });
  }

  fetchMonitorsCount() {
    this.monitors.count().then(count => {
      this.count = count;
      this.session.updateLastReload('monitors-card');
    }).catch(() => this.session.logout());
  }

}
